import React, { Component } from 'react';
import './App.css';

class App extends Component {
  
  render() {

    return (

      <div>
        <h1>This app does nothing.</h1>
        <h2>No, really, nothing.</h2>
        <p>I mean it.</p>
      </div>

    )

  }
  
}

export default App;
